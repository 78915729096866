import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
const TutorialsPage = () => {
  return (
    <>
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <p>Tutoriales Page</p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
export default TutorialsPage;
