import Orders from "MyApp/components/orders/order-list/Orders";
import React from "react";

const PagosListComponent = () => {
  return (
    <>
      <Orders />
    </>
  );
};

export default PagosListComponent;
