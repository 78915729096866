export const orderList = [
  {
    id: "#181",
    name: "Ricky Antony",
    email: "ricky@example.com",
    date: "20/04/2019",
    suscription: "Suscripcion mensual",
    type: "Premium",
    status: "completed",
    amount: 99,
  },
];
