import React from 'react';

const LogoutPage = () => {
  return (
    <>
      <h1>Logout Page</h1>
    </>
  );
};

export default LogoutPage;
