import FaqBasic from "MyApp/components/faq/faq-basic/FaqBasic";
import React from "react";
const FaqPage = () => {
  return (
    <>
      <FaqBasic />
    </>
  );
};
export default FaqPage;
