import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

const RoleListPage = () => {
  return (
    <>
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <p>Roles y Permisos Page</p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default RoleListPage;
