import React from 'react';

const RecoveryPage = () => {
  return (
    <>
      <h1>Recovery Page</h1>
    </>
  );
};

export default RecoveryPage;
