import React from 'react';

const LoginPage = () => {
  return (
    <>
      <h1>Login Page</h1>
    </>
  );
};

export default LoginPage;
