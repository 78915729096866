import React from 'react';

const MessagesListPage = () => {
  return (
    <>
      <h1>Messages List Page</h1>
    </>
  );
};

export default MessagesListPage;
