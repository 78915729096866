import React from 'react';

const ForgotPage = () => {
  return (
    <>
      <h1>Forgot Page</h1>
    </>
  );
};

export default ForgotPage;
